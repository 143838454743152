* {
  text-decoration: none;
  list-style: none;
}

.everything-wrapper-2 {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 30% 20% 10%;
  justify-content: flex-end;
  height: 100%;
  width: 80%;
  background-color: var(--bg-clr);
  text-decoration: none;
  color: var(--font-clr);
  border-left: 2px solid  var(--button);

}

.notebook-wrapper {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 20% 10%;
  /* padding:1rem; */
}
.notebook-wrapper a {
  color: white;
}

.notebook-div-container {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin: 2px;
  margin-bottom: 14px;
  padding: 10px;
  border-bottom: solid 2px #a6a6a6;
  height:fit-content;
  overflow-y: scroll;
}
.notebook-num-stuff {
  height: 2rem;
  margin-left:0.5rem;
  margin-top:1rem;
}
.spot-list {
  list-style: none;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
  position: relative;
  bottom: 10px;
  /* right: 0px; */
  /* list-style:circle */
}
.add-note-notebook {
  color: var(--button);
}
.spot-list button {
  background-color: var(--bg-clr);
  color: var(--button);
  border: none;
  /* list-style: ; */
  /* border: solid 1px #008f26; */
  /* height: 1.5rem; */
}



.fa-solid {
  color: white;
}

.dot-dot {
  background-color: var(--bg-clr);
  color: var(--button);
  border: none;
  padding: 0%;
  font-size: large;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hidden {
  display: none;
}
.hidden1 {
  display: none;
  font-weight: 100;
  font-size: 10px;
}
.new-notebook-component {
  color: var(--button);
  display: flex;
  align-items: center;
  justify-content: right;
  padding-right: 10%;
  border-bottom: solid 2px var(--border);
  position: relative;
  left: 0%;
}
.new-notebook-component button {
  color: var(--button);
  background-color: var(--bg-clr);
  border: none;
}
#add-note-link:hover{
  cursor: pointer;
}

.add-note-feature {
  position: relative;
  left: 5px;
  font-size: 1rem;
}
.notebook-note-dropdown {
  left: 0%;
  right: 0%;
}
.notebook-note-dropdown-note-li {
  font-size: 15px;
  color: var(--button);
  font-weight: lighter;
  margin-bottom:0.5rem;
  overflow-wrap:anywhere;
}
.drop-down-list {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--button);
  padding: 0%;
}
.create-notebook-feature {
  color: var(--button);
  /* border: solid 1px #008f26; */
}
.update-notebook-feature {
  color: var(--button);
  /* border: solid 1px #008f26; */
}
.add-note-feature {
  color: var(--button);
}
.notebook-ul-note-links {
  /* background-color: #008f26; */
  position:absolute;
  width: 12rem;
  padding:0.5rem;
}

.create-notebook-feature > button {
  margin-bottom:10px;
}

.notebook-content-div {

  background-color: var(--bg-clr);
}

.fa-list {
  cursor: pointer;
  position:relative;
}

.notebook-block {
  height:100%;
}

@media screen and (min-width: 1024px) {
  .notebook-block > *, .notebook-div-container > p {
    font-size:1.5rem;
  }
}

@media screen and (max-width:740px) {

  .notebook-headings {
    font-size: 1.75vw;
  }
  .notebook-div-container > *{
    font-size: 1.75vw;
  }
  .notebook-note-dropdown-note-li {
    font-size: 1.75vw;
  }
  .add-note-feature, .update-notebook-feature > button, #delete-notebook-wrapper > button {
    font-size: 1.75vw;
  }
}

@media screen and (max-width:625px) {
  .notebook-ul-note-links {
    position:absolute;
    width: 10rem;
    padding:0.5rem;
  }

}

@media screen and (max-width: 588px) {
  .add-note-notebook > h1 {
    font-size:4vw;
  }
  .create-notebook-feature > button {
    font-size:2.5vw;
  }
}


@media screen and (max-width:550px) {
  .notebook-ul-note-links {
    position:absolute;
    width: 8rem;
    padding:0.5rem;
  }
  .notebook-block {
    height:100%;
  }

}

@media screen and (max-width:390px) {
  .everything-wrapper-2{
    width:100%;
  }
  .notebook-ul-note-links {
    width: 5rem;
  }
  .notebook-div-container>p, .notebook-block, .notebook-note-dropdown-note-li{
    font-size: .6rem;
  }
  .notebook-div-container {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
