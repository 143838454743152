.app-100-wrapper {
    position: absolute;
    height: 100%;
    width: 100%;

}

.app-wrapper {
    display: flex;
    flex-direction: row;
    background-color: var(--shadows);
    width: 100%;
    height: 100%;
    overflow-x: scroll;

}

.app-navbar {
    background-color: var(--shadows);
    padding: 1em;
    width:20%;
    display: flex;
    flex-direction: column;
    height:100%;
}

.app-contents {
    /* background-color: darkcyan; */
    padding: 1em;
}

@media screen and (max-width: 390px) {
    .app-navbar {
        width:15%;
    }
}
