
#header-wrapper{
    /* border: blue solid 2px; */
    /* display: flex; */
    /* justify-content: center; */
    position: absolute;
    margin-left: 10%;

}

#background-wrapper{
    display: flex;
    justify-content: center;
    position: absolute;

}

#landing-background{
    /* height: 300vh; */
    z-index: 0;
    display: flex;
    align-items: center;
    width:100%;
    height: 100%;
    /* padding: 1rem; */
    /* height: 100vh */
    /* border: red solid 2px */
}
