
/*:root {
    --bg-clr: #1a1a1a;
    --font-clr: #a6a6a6;
    --evernote-green: #008f26
}  */


#tag-list-top-sentence {
    font-size: 0.8rem;
}
#tag-list-top-sentence-tags {
    color: white;
}
#tag-list-top-sentence-notes {
    color: white;
}

.tag-node {
    /* border: 3px solid lightgray; */
    padding: 1em;
    /* margin: 0.3em; */
    border-bottom: 2px solid var(--font-clr);
    border-right: 2px solid white;
}

.tag-node-main-buttons {
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    /* justify-content: space-between; */
    padding-top: 0.5rem;
    width: 100%;
}
.tag-note-main-buttons > button {
    max-width: 0;
}

.tag-node-name-and-num {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
}

.tag-node-name-and-num-name {
    color: white;
}


/* 07-17 add remove icon */
.tag-remove-icon {
    margin-left: 3rem;

}

.tag-remove-icon:hover {
   cursor: pointer;
}

.tag-title-and-new {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tag-title-and-new h1 {
    display: inline-block;
}


.tag-top-number-and-sort {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* #tag-note-list-invisible-tab {
    color: transparent;
} */
.tag-note-list-note-and-remove {
    display: flex;
    /* justify-content: space-between; */
    width: 100%;
    align-items: center;
    padding: 2.5px;
}
.tag-note-list-note-li {
    width: 15rem;
    color: var(--font-clr);
    overflow-wrap:anywhere;
}
.tag-note-list-note-li > * {
    font-size: 0.7rem;
}

.tag-note-list-remove-button {
    height: 2rem;
    width: 5rem;
}
.tag-note-list-ul {
    width: 100%;
    margin: 0.5rem 0
}


.tag-notetest-node {
    border: 3px solid darkcyan;
    padding: 0.5em;
    margin: 0.3em;
}

.tag-notetest-note-controls {
    border: 2px solid green;
    /* color: white; */
}

.tag-notetest-note-controls select {
    color: white;
}

.tag-page-wrapper {
    background-color: var(--bg-clr);
    color: var(--font-clr);
    /* width: 28rem; */
    width: 80%;
    /* test width 07-18 */
    /* margin-left: 10rem; */

    /* display: flex;
    justify-content: column; */

    /* max-width: 28rem; */

    border-left: 2px solid var(--button);
    overflow-y: scroll;
    display: flex;

}

.tag-page-wrapper button {
    font-size: 0.7rem;
    border-radius: 5px;
}

.tag-page-wrapper button > * {
    font-size: 0.7rem;
}

/* top section, above taglist */
.tag-list-top {
    /* border: 1px solid fuchsia; */
    border-bottom: 2px solid var(--font-clr);
    padding: 1rem;
    border-right: 2px solid white;
}

/* .tag-list-rename-delete {
    display: flex;
    align-items: center;
} */

.tag-list-rename-delete {
    display: flex;
    gap: 0.2rem;
    margin-left: 2rem;
}



.tag-list-top-create-and-sort-buttons {
    display: flex;
    justify-content: space-evenly;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
}

#tag-list-top-create-button > * {
    padding: 1rem;
    font-size: 1rem;
}
#tag-list-top-sort-button > * {
    padding: 1rem;
    font-size: 1rem;
}

/* 07/17 create icon */


.tag-list-hover-pointer:hover {
    cursor: pointer;
}


/* for note-- tag buttons, from notes */
.tag-note-tag-button > * {
    background-color: var(--mustard);
}

/* For addTagToSelectNote */
.tag-select-note {
    margin-top: 1rem;
}

.tag-select-note-tag-selections {
    color: var(--font-clr);
    background-color: var(--bg-clr);
    width: 100%;
    margin: 0.5rem 0;
}

.tag-page-center {
    display: flex;
    width: 100%;
    /* margin:auto; */
    /* border: 2px solid red; */
    /* flex-direction: column; */
    height: 100%;
}

.center-within {
    justify-self: center;
    width:fit-content;
    /* overflow-y: scroll; */
    /* margin: auto; */
    /* border-left: 1px solid white; */
    /* border-right: 1px solid white; */


}

.tag-note-list-note-and-remove {
    display: flex;
    width: 30rem;
}


.tag-node-name-and-num-span {
    width: 23rem;
}


@media screen and (min-width:1024px) {
    .tag-list-top > p, .tag-node-name-and-num-name,
    .tag-node-name-and-num-num {
        font-size: 1.5rem;
    }
    .tag-select-note-tag-selections, .tag-select-note > div {
        font-size: 1.2rem;
    }

    .tag-note-list-note-li {
        font-size: 1.2rem;
        /* width: 30rem; */
        width: 20rem;

    }
    .tag-page-wrapper button {
        font-size: 1rem;
    }
    .tag-note-list-remove-button {
        width: 10rem;
    }
    #tag-list-top-sentence {
        font-size:1rem;
    }
}

@media screen and (max-width: 1023px) {
    .tag-node-name-and-num-span {
        width: 18rem;
    }
}

@media screen and (max-width: 740px) {
    .tag-note-list-note-and-remove {
        width: 20rem;
    }
}

@media screen and (max-width:560px) {
    .tag-note-list-note-li {
        width: 11rem;
    }
    .tag-node-name-and-num-span {
        width: 14rem;
    }
    .tag-note-list-note-and-remove {
        width: 16rem;
    }
}

@media screen and (max-width: 482px) {
    .tag-note-list-note-li {
        width: 10rem;
    }
    .tag-note-list-note-and-remove {
        width: 14rem;
    }
    .tag-node-name-and-num-span {
        width: 13rem;
    }
    .tag-list-rename-delete {
        margin-left: 1rem;
    }
    .tag-remove-icon{
        margin-left:2rem;
    }
}



@media screen and (max-width:425px) {
    .tag-note-list-note-li {
        width: 6rem;
    }
    .tag-node-name-and-num-span {
        width: 8.5rem;
    }
    .tag-note-list-note-and-remove {
        width: 12rem;
    }
}

@media screen and (max-width:390px) {
    .tag-page-wrapper {
        width:100%;
    }
    .tag-note-list-note-li {
        width: 5rem;
    }
    .tag-note-list-note-and-remove {
        width: 10rem;
    }
}
