/* wraps the profile button */
#profile-button{
    position: static;
    display: flex;
    align-items: center;
    justify-content: center;
    border: var(--button) solid;
    border-radius: 20pt;
    height: 2.9rem;
    width: 2.9rem;
    margin-right: 1.3rem;
    /* margin-bottom: 1rem; */
    z-index: 2;
    /* border: red solid 1px */
    /* background-color: #c49f2f; */
}
/* for the button itself */
#user-button{
    color: black;
    font-size: 30pt;
    position: static;
    /* margin-bottom: 1rem; */
    /* height: 30px; */
    /* width: 30px */
}

#logged-out-button-wrapper{
    margin-top: 4.5%;
    width: 98vw;
    /* position: absolute; */
    display: flex;
    justify-content: flex-end;
    /* flex-direction: column-reverse; */
    /* border: 1px solid red; */
}

#logged-in-button-wrapper {
    margin-top: 2%;
    margin-bottom: .5rem;
    /* height: 5rem; */
    width: 10vw;
    display: flex;
    flex-direction: column;
    /* background-color: var(--bg-clr); */
    /* border: 2px solid red; */
}

.loggedin-dropdown{
    z-index: 2;
    background-color: var(--bg-clr);
    border-radius: 15pt;
    left: 5rem;
    padding:1rem;
    margin: 0.5rem auto;
    font-size: 1.5vw;

}

.profile-dropdown{
    /* border: red solid 1 px; */
    margin-right: .6rem;
}

.ulClassName > li {
    font-size: 1.5vw;
}

#user-logout-btn {
    font-size: 1.5vw;
    margin:  0.4rem 0.3rem 0 ;
    border-radius: 5px;
    border: none;
}


/* working on adjusting profile button for small screen size here */
@media screen and (max-width: 480px) {
    #user-button {
        font-size:20pt;
    }
    #profile-button {
        width: 2rem;
        height: 2rem;
    }
    #user-logout-btn {
        width: 100%;
        margin: 1vw auto 0;
    }
    .loggedin-dropdown {
        padding: 0.5rem;
        border-radius: 15px;
    }
}
