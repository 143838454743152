.drop-down-list {
  background-color: #1a1a1a;
  border: none;
  color: #a6a6a6;
}

.errors {
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
  margin: 0%;
  padding: 0%;
  height: 30px;
}

.delete-modal-div-wrapper {
  border: solid 1px var(--border);
  background-color: var(--bg-clr);
  color: var(--modal);
  padding: 3%;
  width: 20rem;
  height: 100%;
}

.delete-h1 {
  font-size: 22px;
  height: 40px;
  color: var(--font-clr);
}
.delete-p {
  color: var(--font-clr);
  font-weight: 700;
  font-size: 15px;
}
.delete-button-container {
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content: center;
  align-items: center;
  height: 4rem;
  column-gap: 10px;
  /* border: solid 1px rgb(0, 255, 30); */
}
.button-div {
  display: grid;
  justify-content: center;
  align-items: center;
}
.delete-button-container button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  width: 7rem;
  font-weight: bold;
}

.delete-modal-button-submit {
  background-color: var(--button);
  border-radius: 20pt;
  color: var(--modal-txt);
}

.delete-modal-button-cancel {
  background-color: var(--button);
  color: var(--modal-txt);
  border-radius: 20pt;
}
.delete-modal-button-submit:hover {
  background-color: var(--modal-button-cancel);
}

.delete-modal-button-cancel:hover {
  background-color: var(--evernote-green);
}
