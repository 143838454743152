.notebook-detail-main-ultra-wrapper {
  width: 60%;
}
.able  {
  background-color: green;
}
.disable  {
  background-color: red;
}
#whole-notebook-wrapper {
  background-color: var(--bg-clr);
  height: fit-content;
  width: 80%;
  border-left: 2px solid var(--button);
  /* padding: 2rem; */
}

.notebook-content-wrapper {
  display: grid;
  grid-template-columns: 35% 65%;
  column-gap: 10%;
}
.notebook-detail-h1 {
  color: var(--font-clr);
}
.notebook-detail-title {
  color: var(--font-clr);
  cursor: pointer;
}

.notebook-detail-content-right {
  display: grid;
  grid-template-columns: 100%;
}
.notebook-detail-textarea-title {
  color: var(--font-clr);
  width: 80%;
  background-color: rgb(37, 37, 40);
}
.notebook-detail-textarea-body {
  resize: none;
  width: 80%;
  height: 20rem;
  color: var(--font-clr);
  background-color: rgb(37, 37, 40);
}
.div-delete-note {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 4rem;
}
.hidden>*{
  display: none;
}
.notebook-Delete-Note {
  /* height: \rem; */
  border-radius: 15pt;
}
.notebook-Delete-Note button {
  background-color: rgba(255, 255, 255, 0);
  /* height: 2.5rem; */
  /* width: 2.5rem; */
  border: none;
  /* border-radius: 12px; */
  color: black;
  font-size: 2rem;
  font-weight: 500;
}
.notebook-details-form {
  width: 80%;
}
.notebook-detail-button-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 80%;
}
.notebook-content-left {
  width: 100%;
}
.notebook-detail-note-li {
  border-bottom: solid 1px var(--border);
  margin-top: 5px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Create-New-Note {
  /* border: solid 1px green; */
  background-color: var(--button);
}
.Update-New-Note {
  background-color: var(--button);
}
.Reset-Note {
  background-color: var(--button);
  width: 100%;
}

#notebook-details-h1 {
  padding: 1rem;
}
.notebook-detail-button-container > button {
  border-radius: 8px;
}
.notebook-detail-title.currentNoteSelector {
  font-size: 2rem;
}

#delete-note-modal-container > button {
  font-size: 2rem;
}

@media screen and (max-width: 740px) {
  #delete-note-modal-container > button {
    font-size: 3vw;
  }

  .notebook-detail-title.currentNoteSelector {
    font-size: 3vw;
  }
}

@media screen and (max-width: 390px) {
  #whole-notebook-wrapper {
    width: 100%;
  }
}

@media screen and (max-width: 350px) {
  #notebook-details-h1 {
    font-size: 8vw;
  }
}
