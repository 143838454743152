
#modal-content{
    border-radius: 12pt;
}

#login-modal-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--bg-clr);

}

#login-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* border: red solid 1px */
}

#login-input{
    background-color: white;
    color: black;
    margin: 0 0.5rem;
}

#form-items-wrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    /* margin:1rem; */
    padding:1rem 2rem
}

#form-items{
    margin-top: 1rem;
}


#login-button{
    width: 10rem;
    margin-bottom: 1rem;
}
