.drop-down-list {
  background-color: #1a1a1a;
  border: none;
  color: #a6a6a6;
}

.errors {
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
  margin: 0%;
  padding: 0%;
  height: 30px;
}

.create-modal-div-wrapper {
  border: solid 1px var(--border);
  background-color: var(--bg-clr);
  height: 100%;
  width: 25rem;
  color: var(--button);
}

.create-modal-form {
  display: grid;
  margin: 1%;
  padding: 1%;
}

.create-modal-label {
  color: var(--button);
}
.create-des {
  color: var(--font-clr);
}

.create-div-label-input {
  display: flex;
  justify-content: center;
}
.create-modal-form-input {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 30px;
  background-color: white;
  border: solid 1px #a6a6a666;
  border-radius: 5px;
  color: black;
}
.create-button-container {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: 50% 50%;
  height: 4rem;
  column-gap: 10px;
}
.create-button-container button {
  background-color: var(--button);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  width: 9rem;
  font-weight: bold;
}
.notebook-create-button-div {
  display: grid;
  justify-content: center;
  align-items: center;
}
.create-modal-button-submit {
  background-color: var(--bg-clr);
  color: var(--modal-txt);
  border: solid 0.5px var(--border);
}

.create-modal-button-cancel {
  background-color: var(--bg-clr);
  border: solid 0.5px var(--border);
  color: var(--modal-txt);
}
.create-modal-button-submit:hover {
  background-color: var(--evernote-green);
}

.create-modal-button-cancel:hover {
  background-color: var(--modal-button-cancel);
}

.fa-book {
  color: var(--button);
}

/* mobile L*/
@media screen and (max-width: 425px) {
  .create-modal-div-wrapper {
    border: solid 1px var(--border);
    background-color: var(--bg-clr);
    height: 15rem;
    width: 22rem;
  }
  .errors {
    display: flex;
    justify-content: center;
    align-items: center;
    color: red;
    margin: 0%;
    padding: 0%;
    height: 20px;
    font-size: 13px;
  }
  .create-modal-form {
    /* border: solid 1px blue; */
    display: grid;
    margin: 1%;
    padding: 1%;
    row-gap: 5px;
  }
}
/* mobile M*/
@media screen and (max-width: 375px) {
  .create-modal-div-wrapper {
    border: solid 1px var(--border);
    background-color: var(--bg-clr);
    height: 18rem;
    width: 19rem;
  }
  .errors {
    display: flex;
    justify-content: center;
    align-items: center;
    color: red;
    margin: 0%;
    padding: 0%;
    height: 45px;
    font-size: 15px;
  }
  .create-modal-form {
    /* border: solid 1px blue; */
    display: grid;
    margin: 1%;
    padding: 1%;
    row-gap: 10px;
  }
}
/* mobile S*/
@media screen and (max-width: 320px) {
  .create-modal-div-wrapper {
    border: solid 1px var(--border);
    background-color: var(--bg-clr);
    height: 20rem;
    width: 15rem;
  }
  .errors {
    display: flex;
    justify-content: center;
    align-items: center;
    color: red;
    margin: 0%;
    padding: 0%;
    height: 45px;
    font-size: 13px;
  }
}
