#modal-content {
    border-radius: 12pt;
    padding: 2rem;
}

#sign-up-title{
    margin:0;
    /* border: red solid */
}


#signup-modal-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

}

#signup-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* border: red solid */
}

#form-items-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    /* margin:1rem; */
    padding: 1rem 2rem
}

#form-items {
    margin-top: 1rem;

}


#signup-button {
    width: 10rem;
    margin: 2rem 0
}

.input {
    color:black;
    margin: 0 0.5rem;
}
