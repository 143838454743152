.errors {
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
  margin: 0%;
  padding: 0%;
  height: 30px;
}

.update-modal-div-wrapper {
  border: solid 1px var(--border);
  background-color: var(--bg-clr);
  height: 10rem;
  width: 20rem;
}

.update-modal-form {
  display: grid;
  margin: 1%;
  padding: 1%;
}

.update-modal-label {
  color: var(--font-clr);
}
.update-dis {
  color: var(--font-clr);
  height: 2rem;
  font-size: larger;
  display: flex;
  justify-content: center;
  align-items: center;
}

.update-div-label-input {
  display: flex;
  justify-content: center;
  height: 2rem;
}
.update-modal-form-input {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 30px;
  background-color: white;
  border: solid 1px #a6a6a666;
  border-radius: 5px;
  color: black;
}
.update-button-container {
  display: grid;
  justify-content: flex-end;
  align-items: center;
  height: 3rem;
  /* column-gap: 10px; */
  grid-template-columns: 50% 50%;
}
.update-button-container button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  width: 7rem;
  font-weight: bold;
}

.update-modal-button-submit {
  border-radius: 20pt;
  background-color: var(--button);
  padding: 0.25rem;
  font-size: 12pt;
  color: var(--modal-txt);
}
.update-modal-button-cancel {
  border-radius: 20pt;
  background-color: var(--button);
  padding: 0.25rem;
  font-size: 12pt;
  color: var(--modal-txt);
}
.update-modal-button-submit:hover {
  background-color: var(--evernote-green);
}
.update-modal-button-cancel:hover {
  background-color: var(--modal-button-cancel);
}

.update-div {
  display: grid;
  justify-content: center;
  align-items: center;
}
/* mobile L*/
@media screen and (max-width: 425px) {
  .update-modal-div-wrapper {
    border: solid 1px var(--border);
    background-color: var(--bg-clr);
    height: 10rem;
    width: 22rem;
  }
  .errors {
    display: flex;
    justify-content: center;
    align-items: center;
    color: red;
    margin: 0%;
    padding: 0%;
    height: 20px;
    font-size: 13px;
  }
}
/* mobile M*/
@media screen and (max-width: 375px) {
  .update-modal-div-wrapper {
    border: solid 1px var(--border);
    background-color: var(--bg-clr);
    height: 10rem;
    width: 19rem;
  }
  .errors {
    display: flex;
    justify-content: center;
    align-items: center;
    color: red;
    margin: 0%;
    padding: 0%;
    height: 45px;
    font-size: 11px;
  }
}
/* mobile S*/
@media screen and (max-width: 320px) {
  .update-modal-div-wrapper {
    border: solid 1px var(--border);
    background-color: var(--bg-clr);
    height: 10rem;
    width: 15rem;
  }
  .errors {
    display: flex;
    justify-content: center;
    align-items: center;
    color: red;
    margin: 0%;
    padding: 0%;
    height: 45px;
    font-size: 13px;
  }
}
